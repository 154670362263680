import { computed, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useStorage } from "@vueuse/core";
import { isMobileBrowser as checkIsMobileBrowser } from "@/util/dom/functions";
import { useAuthStore } from "@/stores/auth";

export const useLayoutStore = defineStore("layout", () => {
  const authStore = useAuthStore();
  const { membersAuthToken } = storeToRefs(authStore);

  const navbarOpened = useStorage("Bioniq-Navbar-Opened", !checkIsMobileBrowser());

  const isMobileBrowser = computed(() => checkIsMobileBrowser());

  const setNavbarOpened = (val: boolean) => {
    navbarOpened.value = val;
  };

  watch(
    () => membersAuthToken.value,
    (newToken) => !newToken && setNavbarOpened(true)
  );

  return { navbarOpened, isMobileBrowser, setNavbarOpened };
});
