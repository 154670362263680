import { ref } from "vue";
import { defineStore } from "pinia";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { API } from "@/util/api/api_paths";
import { axiosInstance, setAuthTokenOnAxiosClient } from "@/util/api/axios_instance";
import { useMemberStore } from "@/stores/member";
import { useStorage } from "@vueuse/core";
import { useRouter } from "vue-router";

export const useAuthStore = defineStore("authStore", () => {
  const router = useRouter();
  const email = ref<string>("");
  const isLoggedIn = ref<boolean>(false);
  const emailChange = ref<string>("");
  const membersAuthToken = useStorage("Bioniq-Members-Auth-Token", "");

  const fetchCheckEmail = async (emailParam: string) => {
    email.value = emailParam;
    try {
      const res = await axiosInstance.get(API.CHECK_EMAIL, { params: { email: emailParam } });
      const data = camelcaseKeys(res.data);

      return [false, data.passwordMustBeReset];
    } catch (err: any) {
      console.error("Error on /check_email API, ", err);

      if (err.response.status === 409) return [{ confirmEmail: true }, false];
      if (err.response.status === 404) {
        return [{ newUser: true }, false];
      }
      return [true, false];
    }
  };

  const postSignIn = async (password?: string) => {
    const params: { email: string; password?: string } = { email: email.value };
    if (password) params.password = password;
    try {
      const res = await axiosInstance.post(API.SIGN_IN, params);
      const data = camelcaseKeys(res.data);

      if (!data.session.confirmed) return [false, true]; // * Magic Link flow

      setMembersAuthToken(data.session.token);
      const success = await login();
      if (!success) throw true;

      return [false, true];
    } catch (err) {
      console.error("Error on /sign_in API, ", err);
      setMembersAuthToken("");
      return [true, false];
    }
  };

  const confirmSignIn = async (token: string) => {
    const payload = snakecaseKeys({ confirmationToken: token });
    try {
      const res = await axiosInstance.post(API.SIGN_IN_CONFIRM, payload);
      const data = camelcaseKeys(res.data);

      if (!data.session.confirmed) return [true, false];

      setMembersAuthToken(data.session.token);
      const success = await login();
      if (!success) throw true;

      return [false, true];
    } catch (err) {
      console.error("Error on /sign_in/confirm API, ", err);
      return [true, false];
    }
  };

  const postRequestPasswordReset = async (emailParam: string) => {
    try {
      await axiosInstance.post(API.REQ_RESET, { email: emailParam });

      email.value = emailParam;

      return [false, true];
    } catch (err) {
      console.error("Error on /request_reset API, ", err);
      return [true, false];
    }
  };

  const postConfirmPasswordRequest = async ({ password, token }: { password: string; token: string }) => {
    const payload = snakecaseKeys({ confirmationToken: token, newPassword: password });
    try {
      const res = await axiosInstance.post(API.CONFIRM_RESET, payload);
      const data = camelcaseKeys(res.data);

      setMembersAuthToken(data.session.token);
      const success = await login();
      if (!success) throw true;

      return [false, true];
    } catch (err) {
      console.error("Error on /confirm_reset API, ", err);
      setMembersAuthToken("");
      return [true, false];
    }
  };

  const confirmSignUpEmail = async (token: string) => {
    const payload = snakecaseKeys({ confirmationToken: token });
    try {
      const res = await axiosInstance.post(API.SIGN_UP_CONFIRM, payload);
      const data = camelcaseKeys(res.data);
      return [false, { passwordMustBeReset: data.passwordMustBeReset, resetToken: data.passwordResetToken }];
    } catch (err) {
      console.error("Error on /sign_up/confirm API, ", err);
      return [true, false];
    }
  };

  const postUserSignUp = async (password: string) => {
    const payload = { email: email.value, password };
    try {
      const res = await axiosInstance.post(API.SIGN_UP, payload);
      const data = camelcaseKeys(res.data);
      return [false, data];
    } catch (err) {
      console.error("Error on /sign_up API, ", err);
      return [true, false];
    }
  };

  const putRequestEmailChange = async (email: string) => {
    emailChange.value = email;
    try {
      const res = await axiosInstance.put(API.REQ_EMAIL_RESET, { email });
      const data = camelcaseKeys(res.data);
      return [false, data];
    } catch (err) {
      console.error("Error on request email change API, ", err);
      return [true, false];
    }
  };

  const confirmEmailChange = async (token: string) => {
    setMembersAuthToken("");
    const payload = snakecaseKeys({ confirmationToken: token });
    try {
      await axiosInstance.post(API.CONFIRM_EMAIL_CHANGE, payload);
      return [false, true];
    } catch (err) {
      console.error("Error on /email/confirm API, ", err);
      return [true, false];
    }
  };

  const login = async () => {
    if (!membersAuthToken.value) return false;
    setAuthTokenOnAxiosClient(membersAuthToken.value);
    const memberStore = useMemberStore();
    const error = await memberStore.fetchMemberProfile();
    if (error) {
      setMembersAuthToken("");
      isLoggedIn.value = false;
      return false;
    }

    isLoggedIn.value = true;
    return true;
  };

  const logout = () => {
    setMembersAuthToken("");
  };

  const redirectTo = (name: string, query?: any) => {
    router.push({ name, query: { ...query } });
  };

  const setMembersAuthToken = (token: string) => {
    membersAuthToken.value = token;
  };

  const resetData = () => {
    email.value = "";
    isLoggedIn.value = false;
    emailChange.value = "";
    membersAuthToken.value = "";
  };

  return {
    // State
    email,
    isLoggedIn,
    emailChange,
    membersAuthToken,

    // Actions
    fetchCheckEmail,
    postSignIn,
    confirmSignIn,
    postRequestPasswordReset,
    postConfirmPasswordRequest,
    confirmSignUpEmail,
    postUserSignUp,
    putRequestEmailChange,
    confirmEmailChange,
    login,
    logout,
    redirectTo,
    resetData,
    setMembersAuthToken,
  };
});
