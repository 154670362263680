import { ref } from "vue";
import { computed } from "vue";
import { LegalLinks, DACH, CountryCode } from "@/util/constants";

export function useLegal() {
  const country = ref(navigator.language);

  const filteredCountry = computed(() => {
    const langCodeArray = country.value.split("-");

    if (langCodeArray.length === 1) {
      return langCodeArray[0].toUpperCase();
    } else if (langCodeArray.length > 1) {
      return langCodeArray[1].toUpperCase();
    }
    return "";
  });
  const isDachMarket = computed(() => {
    const includeSwitzerland = true;
    const includeNetherlands = true;
    return (
      filteredCountry.value === DACH.DE ||
      filteredCountry.value === DACH.AT ||
      (includeNetherlands && filteredCountry.value === DACH.NL) ||
      (includeSwitzerland && filteredCountry.value === DACH.CH)
    );
  });

  const termsAndConditionsUrl = computed(() => {
    const country = filteredCountry.value as CountryCode;

    if (!LegalLinks[country] && !isDachMarket.value) return LegalLinks[CountryCode.US].terms;

    if (isDachMarket.value) return LegalLinks[CountryCode.DE].terms;

    return LegalLinks[country].terms;
  });

  return { termsAndConditionsUrl };
}
