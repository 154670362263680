export enum LOCALE {
  DE = "de",
  EN = "en",
  RU = "ru",
}

export enum DACH {
  DE = "DE",
  AT = "AT",
  NL = "NL",
  CH = "CH",
}
export enum CountryCode {
  DE = "DE",
  US = "US",
}

export const DEFAULT_LOCALE = LOCALE.EN;

export const LegalLinks: { [key in CountryCode]: any } = {
  [CountryCode.DE]: {
    privacy: "https://bioniq.com/de/privacy",
    imprint: "https://bioniq.com/de/go/imprint",
    terms: "https://assets.website-files.com/63318fb88d52826e7d11babb/634432bf7c8fd4bd12e2222f_AGB%27s%20bioniq.pdf",
  },
  [CountryCode.US]: {
    privacy: "https://bioniq.com/en/privacy",
    imprint: "https://bioniq.com/en/go/imprint",
    terms:
      "https://assets.website-files.com/60d49395935bba3c2a224f74/6286084866ee75e661464c09_TermsConditions-v.7%20may_2022_%20ENG%20(1).pdf",
  },
};

export enum Currency {
  EUR = "EUR",
  USD = "USD",
}

export const APP_STORE_URL = {
  android: "https://play.google.com/store/apps/details?id=com.vaha.app",
  ios: "https://apps.apple.com/app/vaha/id1484631962",
};

export const BUY_UPLOAD_BLOOD_TEST_CREDITS_LINKS = {
  development: "https://cart-staging.bioniq.com/cart/bloodtest-upload",
  staging: "https://cart-staging.bioniq.com/cart/bloodtest-upload",
  production: "https://cart.bioniq.com/cart/bloodtest-upload",
};

export const MESSAGE_PAGE_SIZE = 10;

export enum ProfileInfoMandatoryKeys {
  AVATAR = "avatar",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PH_NUMBER = "phoneNumber",
  GENDER = "gender",
  BIRTHDAY = "birthday",
  HEIGHT = "height",
  WEIGHT = "weight",
}
