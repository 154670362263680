<script setup lang="ts">
import { ref, computed } from "vue";
import { RouteNameMenu } from "@/types/AuthTypes";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useLegal } from "@/composables/useLegal";
import { useMemberStore } from "@/stores/member";
const { t } = useI18n();

const { termsAndConditionsUrl } = useLegal();
const router = useRouter();
const memberStore = useMemberStore();

const questionnaireStarted = ref(false);

const firstTimeUser = computed<boolean>(() => {
  return (
    !!memberStore.mandatoryUncompletedQuestionnaires.length &&
    !memberStore.isMandatoryProfileInfoCompleted &&
    !questionnaireStarted.value
  );
});
</script>

<template>
  <div class="questionnaire-layout">
    <div class="top-row">
      <div class="bioniq-logo">
        <img
          src="@/assets/logos/bioniq-logo.svg"
          alt="bioniq logo"
          @click="router.push({ name: RouteNameMenu.MENU_ANALYTICS })"
        />
      </div>
      <UserAvatar class="questionnaire" />
    </div>

    <div class="center-area">
      <QuestionnaireDescriptiveScreen v-if="firstTimeUser" @startQuestionnaire="questionnaireStarted = true" />
      <slot v-else></slot>
    </div>
    <div class="bottom-row">
      <div class="align-left">
        <div class="small-logo">
          <img src="@/assets/logos/bioniq-logo-sml.svg" alt="bioniq logo small" />
        </div>
        <i18n-t class="terms-cond" keypath="QUESTIONNAIRES.TERMS_COND_FULL" tag="div">
          <a :href="termsAndConditionsUrl" target="_blank" data-link="terms">{{ t("QUESTIONNAIRES.TERMS_COND") }}</a>
        </i18n-t>
      </div>
    </div>

    <div class="bioniq-big-logo"></div>
  </div>
</template>

<style scoped>
@import "@/assets/base.css";

.questionnaire-layout {
  position: relative;
  margin: 4rem;
  padding-left: 0.5em;
  font-family: Montserrat;
  display: grid;
  min-height: calc(100vh - 8rem);
}
.top-row {
  display: flex;
  justify-content: space-between;
}
.top-row .bioniq-logo {
  display: flex;
  align-items: center;
}
.top-row .bioniq-logo img {
  cursor: pointer;
}
.bioniq-logo img {
  width: 8rem;
}
.center-area {
  margin-bottom: 2em;
}
.bottom-row {
  display: flex;
}
.bottom-row .small-logo {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 0.5em;
}
.bottom-row .small-logo img {
  width: 100%;
  height: 100%;
}
.align-left {
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottom-row .terms-cond {
  font-size: 0.8rem;
  color: var(--clr-grey-6);
  letter-spacing: 0.03rem;
  line-height: 1.1rem;
}
.bioniq-big-logo {
  position: absolute;
  background-image: url("@/assets/logos/bioniq-logo-big-B.svg");
  width: 45%;
  height: 100%;
  right: 4rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}

@media (--max-laptop-width) {
  .questionnaire-layout {
    margin: 3rem;
    min-height: calc(100vh - 6rem);
  }
  .bottom-row .terms-cond {
    font-size: 0.7rem;
  }
}
@media (--max-phone-width) {
  .questionnaire-layout {
    margin: 1rem;
    min-height: calc(100vh - 2rem);
  }
  .top-row .bioniq-logo {
    width: 5rem;
  }
  .top-row .bioniq-logo img {
    width: 100%;
  }
  .bottom-row .terms-cond {
    font-size: 0.7rem;
    letter-spacing: normal;
    line-height: normal;
  }
}
</style>
